import styled from 'styled-components';
import breakpoint from  'styled-components-breakpoint';

/*
 * Styles
 */
export const ArticlesPreviewSection = styled.section`
  background: #FFF;
  padding: 40px 20px;
  
  ${breakpoint('tablet')`
    padding: 80px 20px;
  `};
`;

export const SliderContainer = styled.span`
  ${breakpoint('tablet')`
    padding: 20px 0px;
    display: block;
  `};
`;


export const SectionHeading = styled.h1`
  font-size: 34px;
  
  ${breakpoint('tablet')`
    text-align: center;
  `};
`;
