import React, { Fragment } from 'react';
import Slider from "react-slick";
import { graphql, navigate, StaticQuery } from 'gatsby';
import ArticleCard from '../article-card/articleCard'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slickStyles.scss";
import { ArticlesPreviewSection, SectionHeading, SliderContainer } from './styles';
import Button from '../button/button'
import { isWide } from '../../utils/responsive';

class ArticlesPreview extends React.Component {
  render() {
    const {
      articles,
      title,
      button
    } = this.props;

    const slickSettings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: isWide ? 4 : 1,
      slidesToScroll: 1
    };

    return (
      <ArticlesPreviewSection>
        <SectionHeading>{ title }</SectionHeading>
        <SliderContainer>
          <Slider { ...slickSettings }>
            {
              articles.map(data => <ArticleCard { ...data } key = { `article-${data.id}` } />)
            }
          </Slider>
        </SliderContainer>
        <div style = { { display: 'flex', justifyContent: 'center', marginTop: '20px' } }>
          <Button text = { button } onClickHandler = { () => navigate('/news') } black />
        </div>
      </ArticlesPreviewSection>
    );
  }
}

export default ArticlesPreview;
