import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const CardContainer = /*withReveal(*/ styled.article`
  background: #eee;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  cursor: pointer;

  ${breakpoint('tablet')`
    height: 500px;
    display: inline-block;
    margin-right: 15px;
  `};
` /*, <Fade />)*/

export const Title = styled.h3`
  font-family: 'Brown-Pro-Bold', AdobeBlank, Serif;
  font-weight: 500;
  margin: 5px 0;
`

export const Subheading = styled.p`
  font-family: 'Brown-Pro-Light', AdobeBlank, Serif;
  font-weight: 300;
  margin: 5px 0;
  font-size: 12px;
  color: #333;
`

export const InnerContainer = styled.div`
  padding: 20px 20px 40px;
  height: 60%;
  position: relative;
`

export const ShortIntroduction = styled.p`
  font-family: 'Brown-Pro-Regular', AdobeBlank, Serif;
  font-weight: 300;
`

export const PreviewImageContainer = styled.span`
  max-width: 100%;
  width: 100%;
  height: 200px;
  display: block;
  object-fit: cover;
`

export const PreviewImage = styled.img`
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Tags = styled.h5`
  position: absolute;
  bottom: 0;
  font-size: 12px;
  color: #ffffff;
  border-radius: 4px;
  padding: 5px 10px;
  display: inline;
  margin-top: -20px;
  z-index: 10;

  ${({ color }) =>
    color &&
    `
    background: ${color}
  `}
`
