import React, { Fragment } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import ArticlesPreview from './articlesPreview'
import { extractArticles } from '../../utils/selectors';
import { isWide } from '../../utils/responsive';

const ArticlesPreviewContainer = () => (
  <Fragment>
    <StaticQuery
      query = { query }
      render = { data =>
        <ArticlesPreview articles = { extractArticles(data.allPrismicArticle).slice(0, isWide ? 8 : 3) } title = { data.allPrismicNews.edges[0].node.data.homepage_preview_heading.text } button = { data.allPrismicNews.edges[0].node.data.homepage_find_out_more_label.text } />
    }
    />
  </Fragment>
);

const query = graphql`
  query GetArticles {
    allPrismicArticle(sort: { fields: data___article_order_index , order:DESC }) {
      edges {
        node {
          ...Article
        }
      }
    }

      allPrismicNews {
          edges {
              node {
                  data {
                      homepage_preview_heading {
                          text
                      }
                      homepage_find_out_more_label {
                          text
                      }
                  }
              }
          }
      }
  }
  `;


export default ArticlesPreviewContainer
